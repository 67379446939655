<template>
  <div
    :style="
      'background-color: #34464B; background-image: url(' +
        backgroundImage +
        '); background-size: cover;'
    "
  >
    <div
      style="max-width: 1400px; margin: auto;     padding-top: 20px;
    padding-bottom: 20px;"
    >
      <h1 class="p-m-3 " style="color: white;">
        <p class="p-m-0" style="" v-if="conference">
          {{ conference.year }} {{ conference.conferenceName }},
          {{ conference.city }} <br />
          <span style="font-size: 20px; font-weight: 400;">{{
            conference.displayDate
          }}</span>
        </p>
      </h1>
    </div>
  </div>
  <div style="max-width: 1400px; margin: auto; margin-top: 15px;">
    <div v-if="loadingCfps" style="text-align: center;">
      <ProgressSpinner />
    </div>
    <div v-else>
      <div>
        <div class="p-d-flex p-flex-wrap p-ai-center">
          <div class="statwrapper">
            <!-- "IT/Technical", "Management", "Compliance", "Product Demo" -->
            <div class="statcard">
              <p class="statheadline">IT/Technical</p>
              <p>
                {{
                  matchedCfps.filter((e) => e.audience == "IT/Technical").length
                }}
              </p>
            </div>
            <div class="statcard">
              <p class="statheadline">Management</p>
              <p>
                {{
                  matchedCfps.filter(
                    (e) => e.audience == "Management/Personal Development"
                  ).length
                }}
              </p>
            </div>
            <div class="statcard">
              <p class="statheadline">Compliance</p>
              <p>
                {{
                  matchedCfps.filter((e) => e.audience == "Compliance").length
                }}
              </p>
            </div>
            <div class="statcard">
              <p class="statheadline">Product Demo</p>
              <p>
                {{
                  matchedCfps.filter((e) => e.audience == "Product Demo").length
                }}
              </p>
            </div>
            <div class="statcard">
              <p class="statheadline">Approved</p>
              <p>
                {{ matchedCfps.filter((e) => e.status == "accepted").length }}
              </p>
            </div>
            <div class="statcard">
              <p class="statheadline">Declined</p>
              <p>
                {{ matchedCfps.filter((e) => e.status == "declined").length }}
              </p>
            </div>
          </div>
          <!-- <div class="card p-shadow-2 p-m-3 p-p-3 brw">
            <h5 class="p-text-center">Audience</h5>
            <p>
             
            </p>
            <button @click="updateChart">update</button>
            <Chart type="pie" :data="chartData" :options="options" />
            <Chart type="pie" :data="myChartData" :options="myLightOptions" />
          </div> -->
          <!-- <div class="card p-shadow-2 p-m-3 p-p-3 brw">
            <h5 class="p-text-center">Status</h5>

            <Chart v-if="showStatData" type="pie" :data="statData" />
          </div> -->
        </div>
      </div>
      <div class="card p-m-3 p-p-3 p-shadow-2 brw">
        <div class="p-mb-2">
          <Button
            label="Export All"
            class="p-mr-1"
            icon="pi pi-download"
            @click="exportAll"
          />
          <Button
            label="Export Approved"
            icon="pi pi-download"
            @click="exportApproved"
          />
        </div>

        <DataTable
          :value="matchedCfps"
          stripedRows
          responsiveLayout="scroll"
          breakpoint="810px"
        >
          <template #header>
            Submitted Presentations
          </template>
          <Column field="title" header="Title" :sortable="true">
            <template #body="slotProps">
              <div
                class="speaker-link"
                @click="
                  router.push({
                    name: 'ViewCfp',
                    params: {
                      id: slotProps.data.uid,
                    },
                  })
                "
              >
                {{ slotProps.data.title }}
              </div>
            </template>
          </Column>
          <Column field="lastName" header="Presenter" :sortable="true">
            <template #body="slotProps">
              <div
                class="speaker-link"
                @click="
                  router.push({
                    name: 'Speaker',
                    params: {
                      id: slotProps.data.userUid,
                    },
                  })
                "
              >
                <div class="p-d-flex p-ai-center">
                  <Avatar
                    :image="slotProps.data.imageCropUrl"
                    shape="circle"
                    class="p-mr-1"
                  />
                  {{ slotProps.data.firstName }} {{ slotProps.data.lastName }}
                </div>
              </div>
            </template>
          </Column>
          <Column :sortable="true" field="audience" header="Audience"> </Column>

          <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              <div class="p-d-flex p-ai-center">
                <span
                  :class="'cfp-status ' + ` ${slotProps.data.status}-cfp`"
                  >{{ slotProps.data.status }}</span
                >
              </div>

              <!-- <span :class="'product-badge status-' + (slotProps.data.inventoryStatus ? slotProps.data.inventoryStatus.toLowerCase() : '')">{{slotProps.data.status}}</span> -->
            </template>
          </Column>
          <Column header="">
            <template #body="slotProps">
              <div :id="slotProps.data.uid">
                <Button
                  class="p-button-outlined p-button-secondary"
                  :id="slotProps.data.uid"
                  type="button"
                  icon="pi pi-bars"
                  @click="
                    toggle($event);
                    currentUid(slotProps.data.uid);
                  "
                  aria-haspopup="true"
                  aria-controls="overlay_menu"
                />
                <Menu
                  :id="slotProps.data.uid + ' overlay_menu'"
                  ref="menu"
                  :data-cfpUid="slotProps.data.uid"
                  :model="items"
                  :popup="true"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <EditCfpModal
      :showModal="displayEditModalnext"
      :user="presentationToEdit"
      :presentation="presentationToEdit"
      @modalVisibility="useHideAddNewConference"
      @pushCfpEdit="usePushCfpEdit"
    />
  </div>
  <Toast position="bottom-right" />
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { ref, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import { useConfirm } from "primevue/useconfirm";
import backgroundImage from "../assets/dark-bg.jpg";
import GetCfps from "../composables/GetCfps";
import GetConference from "../composables/GetConference";
import EditCfpModal from "../components/EditCfpModal.vue";
import EditDocument from "../composables/EditDocument";
import getUser from "../composables/getUser";
import DeleteDocument from "../composables/DeleteDocument";
import ConfirmDialog from "primevue/confirmdialog";
import SaveDocument from "../composables/SaveDocument";

export default defineComponent({
  name: "Conference",
  components: { EditCfpModal, Toast, ConfirmDialog },
  setup() {
    const { addDocument, newDoc, addDocError } = SaveDocument();

    const myChartData = ref({
      labels: ["A", "B", "C"],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
          hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
        },
      ],
    });

    const updateChart = () => {
      myChartData.value = {
        labels: ["A", "B", "C"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
          },
        ],
      };
      console.log(myChartData.value);
    };

    const myLightOptions = ref({
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
    });

    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { useDeleteDocument, deleteDocError, deletedUid } = DeleteDocument();

    const { isAdmin } = getUser();
    const confirm = useConfirm();

    const checkIsAdmin = () => {
      if (isAdmin.value) {
        console.log(isAdmin);
        console.log("admin shown");
        return;
      } else {
        console.log("no admin");
        router.push({
          name: "Home",
        });
      }
    };
    checkIsAdmin();

    const { useEditDocument, newEditedDoc, editDocError } = EditDocument();
    const {
      loadingGetConference,
      getConferenceError,
      conference,
      useGetConference,
    } = GetConference();
    useGetConference(route.params.id);
    const { useGetCfps, matchedCfps, loadingCfps } = GetCfps();
    const loadCfps = async () => {
      await useGetCfps("conferenceUid", route.params.id);
      approvedStat.value = matchedCfps.value.filter(
        (e) => e.status == "accepted"
      ).length;
      pendingStat.value = matchedCfps.value.filter(
        (e) => e.status == "pending"
      ).length;
      declinedStat.value = matchedCfps.value.filter(
        (e) => e.status == "declined"
      ).length;
      console.log("matched for conference", matchedCfps.value, loadingCfps);

      showStatData.value = true;
      statData.value = {
        labels: ["Approved", "Pending", "Declined"],
        datasets: [
          {
            data: [approvedStat.value, pendingStat.value, declinedStat.value],
            backgroundColor: ["#affeb2", "#feedaf", "#feafaf"],
            hoverBackgroundColor: ["#408a52", "#8a5340", "#8a4040"],
          },
        ],
      };
      chartData.value = {
        labels: ["IT/Technical", "Management", "Compliance", "Product Demo"],
        datasets: [
          {
            data: [
              matchedCfps.value.filter((e) => e.audience == "IT/Technical")
                .length,
              matchedCfps.value.filter(
                (e) => e.audience == "Management/Personal Development"
              ).length,
              matchedCfps.value.filter((e) => e.audience == "Compliance")
                .length,
              matchedCfps.value.filter(
                (e) =>
                  e.audience ==
                  "Product Demo [*Fee applies. Limited spots available.]"
              ).length,
            ],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#34eba4"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#34eba4"],
          },
        ],
      };
      console.log("chart data:", chartData.value);
    };
    const approvedStat = ref(1);
    const pendingStat = ref(1);
    const declinedStat = ref(1);
    const showStatData = ref(false);
    loadCfps();

    const presentationToEdit = ref(null);
    const displayEditModalnext = ref(1);
    const editCfp = (confuidevent) => {
      console.log(
        confuidevent,
        "=>",
        matchedCfps.value.find((element) => element.uid == confuidevent)
      );
      presentationToEdit.value = matchedCfps.value.find(
        (element) => element.uid == confuidevent
      );
      displayEditModalnext.value += 1;
    };

    // SEND MESSAGE START

    const useSendMessage = async (presentation, status) => {
      console.log("starting send status message");
      let messageHtml;
      let messagePlainText;
      let messageSubject;
      if (status === "accepted") {
        messageSubject = `${presentation.conference} ${presentation.year} || ${presentation.conferenceCity} || SPEAKER CONFIRMATION`;
        messageHtml = `
        <p>[IMPORTANT, PLEASE READ THIS EMAIL CAREFULLY, AND SAVE FOR FUTURE REFERENCE.]</p>
        <p>Hello ${presentation.firstName},</p>
        <p>Thank you for submitting your presentation "${presentation.title}" at the upcoming <strong>${presentation.conference} conference</strong> to be held Thursday, May 15, 2025 - Friday, May 16, 2025, in ${presentation.conferenceCity}, ${presentation.conferenceState}.</p>
        <p>Congratulations, your presentation has been selected! We are still finalizing the schedule and assigning sessions to specific timeslots, but we wanted to ensure that you SAVE THE DATES for the conference.</p>
        
        <p><strong>All events will be taking place at:</strong><br/>
        Omni Interlocken Hotel<br/>
        500 Interlocken Blvd<br/>
        Broomfield, CO 80021</p>
        <p><em>It is imperative that you guarantee your participation in this event as we have specifically reserved this session for you. We value your support of the HTM Mixer and your contribution to this spectacular event!
</em></p><br/>
        <p>Thank you,</p>
        <p>HTM Mixer Staff</p>
        <p>https://htmmixer.com/</p>
        <p>events@mdpublishing.com</p>
        `;
        messagePlainText = `
        [IMPORTANT, PLEASE READ THIS EMAIL CAREFULLY, AND SAVE FOR FUTURE REFERENCE.] 
        Hello, 
        Thank you for submitting your presentation "${presentation.title}" at the upcoming ${presentation.conference} conference to be held Thursday, May 15, 2025 - Friday, May 16, 2025, in ${presentation.conferenceCity}, ${presentation.conferenceState}.
        Congratulations, your presentation has been selected! We are still finalizing the schedule and assigning sessions to specific timeslots, but we wanted to ensure that you SAVE THE DATES for the conference. 
        
        All events will be taking place at:
        Omni Interlocken Hotel
        500 Interlocken Blvd
        Broomfield, CO 80021

        It is imperative that you guarantee your participation in this event as we have specifically reserved this session for you. We value your support of the HTM Mixer and your contribution to this spectacular event!
 
        Thank you,
        HTM Mixer Staff
        https://htmmixer.com/
        events@mdpublishing.com 
        `;
      } else {
        messageSubject = ` [UPDATE] Presentation status for ${presentation.conference}`;
        messageHtml = `
<p>        Hello,
</p>
<p>Thank you for submitting your presentation for the upcoming ${presentation.conference} conference to be held Thursday, May 15, 2025 - Friday, May 16, 2025, in Broomfield, CO.
</p>
<p>Unfortunately, due to the limited availability for educational sessions, we were unable to accommodate all of the presentations that were submitted. Currently, all of our educational sessions have been filled.</p>
<p>We encourage you to re-submit your presentation "${presentation.title}" for our next conference. 
</p>
<br/>
        <p>Thank you,</p>
        <p>HTM Mixer Staff</p>
        <p>https://htmmixer.com/</p>
        <p>events@mdpublishing.com</p>
`;
        messagePlainText = `Hello,

Thank you for submitting your presentation for the upcoming ${presentation.conference} conference to be held Thursday, May 15, 2025 - Friday, May 16, 2025, in Broomfield, CO.

Unfortunately, due to the limited availability for educational sessions, we were unable to accommodate all of the presentations that were submitted. Currently, all of our educational sessions have been filled.

We encourage you to re-submit your presentation "${presentation.title}" for our next conference. 

Thank you, 
HTM Mixer Staff
https://htmmixer.com/
events@mdpublishing.com `;
      }
      let message = {
        from: "myCFP <mycfp@mdpublishing.com>",
        replyTo: `events@mdpublishing.com`,
        to: [`${presentation.email}`, `events@mdpublishing.com`],

        message: {
          subject: messageSubject,
          html: messageHtml,
          text: messagePlainText,
        },
      };
      console.log(message);
      await addDocument("mail", message);
      if (!addDocError.value) {
        console.log("message sent");
      } else {
        console.log("error sending notification");
      }
    };

    // SEND MESSAGE END

    const changeStatus = (confuidevent, newStatus) => {
      console.log(
        confuidevent,
        "=>",
        matchedCfps.value.find((element) => element.uid == confuidevent)
      );
      presentationToEdit.value = matchedCfps.value.find((element, index) => {
        if (element.uid == confuidevent) {
          matchedCfps.value[index].status = newStatus;
          console.log("cfps", matchedCfps.value[index], confuidevent);
          useEditDocument("cfps", matchedCfps.value[index], confuidevent);

          if (newStatus == "accepted") {
            toast.add({
              severity: "success",
              summary: "Accepted",
              detail: 'Presentation status has been set to "accepted".',
              life: 3000,
            });
            useSendMessage(matchedCfps.value[index], "accepted");
          }
          if (newStatus == "pending") {
            toast.add({
              severity: "warn",
              summary: "Pending",
              detail: 'Presentation status has been set to "pending".',
              life: 3000,
            });
          }
          if (newStatus == "declined") {
            toast.add({
              severity: "error",
              summary: "Declined",
              detail: 'Presentation status has been set to "declined".',
              life: 3000,
            });
            useSendMessage(matchedCfps.value[index], "declined");
          }

          return true;
        }
      });
      // displayEditModalnext.value += 1;
    };

    const usePushCfpEdit = (submission) => {
      console.log("from parent", submission);
      matchedCfps.value.find((element, index) => {
        if (element.uid == activeUid.value) {
          matchedCfps.value[index] = submission;
          return true;
        }
      });
    };
    const activeUid = ref("");
    const currentUid = (uidValue) => {
      activeUid.value = uidValue;
    };
    const items = ref([
      {
        label: "Status",
        items: [
          {
            label: "Approved",
            icon: "pi pi-thumbs-up",

            command: () => {
              changeStatus(activeUid.value, "accepted");
            },
          },
          {
            label: "Pending",
            icon: "pi pi-sort",

            command: () => {
              changeStatus(activeUid.value, "pending");
            },
          },
          {
            label: "Declined",
            icon: "pi pi-thumbs-down",

            command: () => {
              changeStatus(activeUid.value, "declined");
            },
          },
        ],
      },
      {
        label: "Actions",
        items: [
          {
            label: "View",
            icon: "pi pi-eye",

            command: () => {
              router.push({
                name: "ViewCfp",
                params: { id: activeUid.value },
              });
            },
          },
          {
            label: "Edit",
            icon: "pi pi-pencil",

            command: () => {
              editCfp(activeUid.value);
            },
          },
          {
            label: "Delete",
            icon: "pi pi-trash",

            command: () => {
              deleteCfp(activeUid.value);
            },
          },
        ],
      },
    ]);
    const menu = ref();

    const toggle = (event) => {
      menu.value.toggle(event);
    };
    const statData = ref({
      labels: ["Approved", "Pending", "Declined"],
      datasets: [
        {
          data: [approvedStat.value, pendingStat.value, declinedStat.value],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    });
    const chartData = ref({
      labels: ["IT/Technical", "Management", "Compliance", "Product Demo"],
      datasets: [
        {
          data: [2, 1, 3, 4],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FFCE11"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FFCE11"],
        },
      ],
    });
    const options = {
      type: "bar",
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: true,
            text: "Chart.js Bar Chart",
          },
        },
      },
    };

    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }

    const exportApproved = () => {
      let approvedContent = ``;
      matchedCfps.value.forEach((item) => {
        console.log("checking", item);
        if (item.status == "accepted") {
          approvedContent += `Title:\n${item.title}\n\nAudience:\n${item.audience}\n\nPresenter:\n${item.firstName} ${item.lastName}, ${item.company}, ${item.email}\n\nDescription:\n${item.description}\n\nCo-Presenter:\n${item.copresenter}\n\nObjective:\n${item.objective}\n\n#####\n\n`;
        }
      });
      download(approvedContent, "export.txt", "text/plain");
      console.log("attempting to show toast");
      toast.add({
        severity: "success",
        summary: "Exported",
        detail: "Approved CFP's have been exported.",
        life: 3000,
      });
    };
    const exportAll = () => {
      let approvedContent = ``;
      matchedCfps.value.forEach((item) => {
        console.log("checking", item);
        approvedContent += `Title:\n${item.title}\n\nAudience:\n${item.audience}\n\nPresenter:\n${item.firstName} ${item.lastName}, ${item.company}, ${item.email}\n\nDescription:\n${item.description}\n\nCo-Presenter:\n${item.copresenter}\n\nObjective:\n${item.objective}\n\n#####\n\n`;
      });
      download(approvedContent, "export.txt", "text/plain");
      console.log("attempting to show toast");
      toast.add({
        severity: "success",
        summary: "Exported",
        detail: "All CFP's have been exported.",
        life: 3000,
      });
    };

    const deleteCfp = (uid) => {
      console.log(uid);
      confirm.require({
        message: "Are you sure you want to delete this presentation?",
        header: "Confirmation",
        acceptClass: "p-button-danger",
        rejectClass: "p-button-secondary",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          //callback to execute when user confirms the action
          await useDeleteDocument("cfps", uid);
          matchedCfps.value = matchedCfps.value.filter(
            (item) => item.uid != uid
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    return {
      updateChart,
      addDocument,
      addDocError,
      newDoc,
      myChartData,
      myLightOptions,
      backgroundImage,
      deleteDocError,
      deletedUid,
      exportAll,
      exportApproved,
      options,
      showStatData,
      currentUid,
      statData,
      toggle,
      menu,
      items,
      matchedCfps,
      loadingCfps,
      router,
      editCfp,
      presentationToEdit,
      displayEditModalnext,
      loadingGetConference,
      getConferenceError,
      conference,
      useGetConference,
      useEditDocument,
      newEditedDoc,
      editDocError,
      usePushCfpEdit,
      chartData,
    };
  },
});
</script>

<style>
.statheadline {
  color: #495057;
  font-weight: bold;
}
.statwrapper {
  margin: 4px 12px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statcard {
  min-width: 125px;
  color: #495057;
  text-align: center !important;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  justify-content: space-around;
  flex-grow: 1;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}
.speaker-link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #2196f3;
}

.cfp-status {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-align: center;
}
.cfp-status.pending-cfp {
  background: #feedaf;
  color: #8a5340;
}
.cfp-status.declined-cfp {
  background: #feafaf;
  color: #8a4040;
}
.cfp-status.accepted-cfp {
  background: #affeb2;
  color: #408a52;
}
</style>